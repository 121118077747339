import React from 'react';
import '../../styles/components/Button.scss';
import classNames from 'classnames';

function Button(props: {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    link?: boolean;
    icon?: JSX.Element
}) {

    const buttonClass = classNames('button', {
        'button__primary': props.primary,
        'button__secondary': props.secondary,
        'button__tertiary': props.tertiary,
        'button__link': props.link,
    });

    return (
        <button className={buttonClass} onClick={props.onClick}>
            {props.label}
            {props.icon && <span className="cardList__icon">{props.icon}</span>}
        </button>
    );
}

Button.defaultProps = {
    primary: true,
};

export default Button;
