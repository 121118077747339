import React from 'react';
import Button from './Button';
import '../../styles/components/Card.scss';

interface CardProps {
    title: string;
    content1: string;
    content2: string;
    button: {
        onClick: () => void;
        text: string;
    };
    link?: string;
    imageUrl?: string;
}

const Card: React.FC<CardProps> = ({ title, content1, content2, button, link, imageUrl }) => {
    return (
        <div className="card">
            <div className='card__content'>
                <div className='card__content__block'>
                    <div className='card__title'>
                        <span className='card__topico'>Nome do projeto: </span>
                        {title}
                    </div>

                    <div className='card__text'>
                        <span className='card__topico'>Descrição do projeto: </span>
                        {content1}
                    </div>

                    <div className='card__text'>
                        <span className='card__topico'>Minha contribuição: </span>
                        {content2}
                    </div>
                </div>
                
                <div className='card__link'><Button link onClick={button.onClick} label={button.text} /></div>
            </div>

            <div className='card__image'>
                {imageUrl && <img src={imageUrl} alt={title} />}
            </div>
        </div>
    );
}

Card.defaultProps = {
    link: '',
    imageUrl: '',
};

export default Card;
