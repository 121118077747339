import React from 'react';
import CardList from '../components/components/CardList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import '../styles/Info.scss';

export default function Info() {
    const items = [
        { label: "Escola DNC", caption: "Consultora", date: "2024 - Presente" },
        { label: "TOTVS", caption: "Dev Front-End", caption2: "Product Designer", date: "2022 - Presente" },
        { label: "Geopixel", caption: "Dev Front-End", date: "2021 - 2022" },
        { label: "Necto Systems", caption: "Estágiária", caption2: "Dev Front-End", date: "2019 - 2021" },
    ];

    const formacao = [
        {
            label: "Produtos Digitais UX/UI",
            caption: "Unopar",
            date: "2021 - 2022"
        },
        {
            label: "Análise e Desenvolvimento de Sistemas",
            caption: "FATEC",
            date: "2017 - 2020"
        },
    ];

    return (
        <div className='info'>
            <div className='text__destaque'>Para me conhecer um pouco :)</div>
            <h2>Sobre mim</h2>
            <div className='info__content'>
                <div className='info__list'>
                    <CardList title="Experiência" items={items} icon={<FontAwesomeIcon icon={faBriefcase} />} />
                </div>

                <div className='info__list'>
                    <CardList title="Formação" items={formacao} icon={<FontAwesomeIcon icon={faGraduationCap} />} />
                </div>
            </div> 
        </div>
    );
}
