import React from 'react';
import Button from './components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import '../../src/App.css';
import '../styles/Header.scss';

export default function Header() {
    const handleLinkedInClick = () => {
        window.open("https://www.linkedin.com/in/lucy-brandao/", "_blank");
    };

    const handleGithubClick = () => {
        window.open("https://github.com/lucybrandao", "_blank");
    };

    const handleBehanceClick = () => {
        window.open("https://www.behance.net/lucyjbrand3b99", "_blank");
    };

    const handleCVButtonClick = () => {
        window.open("/Lucy_Brandao_CV_PTBR.pdf", "_blank");
    };

    return (
        <div className='header'>
            <div className='headerText'>
                <div className='text__destaque'>Bem vindo ao meu portfólio!</div>
                <h1>Olá! Sou a Lucy Brandão</h1>

                <div className='text__subtitle'>
                    Product designer e desenvolvedora front-end
                    focada em acessibilidade, usabilidade e design
                    consistente.
                </div>

                <div className='headerText__links'>
                    <Button link onClick={handleLinkedInClick} label="LinkedIn" />
                    <Button link onClick={handleGithubClick} label="Github" />
                    <Button link onClick={handleBehanceClick} label="Behance" />
                </div>

                <Button onClick={handleCVButtonClick} label="Visualizar CV" icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />} />
            </div>
        </div>
    )
}
