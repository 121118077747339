import Header from './components/Header.tsx';
import Projects from './components/Projects.tsx';
import Info from './components/Info.tsx';

import './App.css';
import './styles/Base.scss';

function App() {
  return (
    <div className='base'>
      <Header />
      <div className='base__content'>
        <Projects />
        <Info />
      </div>
    </div>
  );
}

export default App;
