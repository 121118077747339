import React from 'react';
import Card from '../components/components/Card';

import '../../src/App.css';
import '../styles/Projects.scss';

const projects = [
  {
    title: "Descanso Curto",
    content1: 'O projeto "Descanso Curto" é um aplicativo web desenvolvido para auxiliar jogadores de RPG na criação e gerenciamento de fichas de personagem, bem como no controle de bônus e outras pontuações relevantes para os jogos.',
    content2: "Assumi o projeto após avaliação inicial como consultora, redesenhando completamente as telas após identificar e resolver problemas significativos, entregando uma versão aprimorada do aplicativo.",
    imageUrl: "mckp2.png",
    buttonText: "Ver mais em: doc.animaliads.io"
  },
  {
    title: "Animalia DS",
    content1: 'O "Animalia DS" é um design system de código aberto desenvolvido pela TOTVS, destinado a aprimorar a eficiência no desenvolvimento de aplicações corporativas, simplificando a comunicação entre equipes de design e desenvolvimento.',
    content2: "Como product designer e desenvolvedora, estive envolvida em todas as etapas do processo de design, desde a concepção até a implementação de componentes para o design system. Também sou responsável pela manutenção do portal do DS.",
    imageUrl: "animaliasquare.png",
    buttonText: "Ver mais em: doc.animaliads.io"
  },
  {
    title: "Geopixel Cidades",
    content1: " Plataforma para integração e gestão de informações municipais, incluindo dados geográficos, imagens e documentos.",
    content2: "Como membro da equipe de front-end da Geopixel, trabalhei em estreita colaboração com o designer para garantir a implementação adequada das diretrizes de design. Além disso, organizei a arquitetura do projeto front-end e supervisionei a qualidade dos PRs, garantindo a fidelidade ao que foi definido no handoff.",
    imageUrl: "gpxsquare.png",
    buttonText: "Ver mais em: geopixel.com.br"
  },
  {
    title: "PO-UI",
    content1: "O projeto da Biblioteca de Componentes PO UI é uma iniciativa de código aberto que utiliza tecnologias modernas como Angular e TypeScript. Surgido na TOTVS, uma empresa líder em soluções de software corporativo na América Latina, com presença em mais de 40 países, sua intenção é retribuir à comunidade todo o apoio que recebeu.",
    content2: "Durante três meses, dediquei-me a apoiar o time do PO UI na TOTVS, realizando pair programming com os membros da equipe.",
    imageUrl: "pouisquare.png",
    buttonText: "Ver mais em: po-ui.io"
  },
];

export default function Projects() {
  const handleButtonClick = () => {
    alert('Botão clicado!');
  };

  return (
    <div className='projects'>
      <div className='text__destaque'>Projetos</div>
      <h2>Conheça o meu trabalho</h2>
      
      <div className='projects__list'>
        {projects.map((project, index) => (
            <Card
              key={index}
              title={project.title}
              content1={project.content1}
              content2={project.content2}
              imageUrl={project.imageUrl}
              button={{ onClick: handleButtonClick, text: project.buttonText }}
            />
        ))}
      </div>
      
    </div>
  );
}
