import React from 'react';
import '../../styles/components/CardList.scss';

function CardList(props: { title: string, items: Array<any>, icon?: JSX.Element }) {
    return (
        <div className="cardList">
            <div className="cardList__content">
                {props.title && (
                    <div className='cardList__title'>
                        {props.icon && <span className="cardList__icon">{props.icon}</span>}
                        {props.title}
                    </div>
                )}

                <ul>
                    {props.items.map((item, index) => (
                        <React.Fragment key={index}>
                            <li>
                                <div className='cardList__item__title'>{item.label}</div>
                                
                                <div className='justify-between align-end'>
                                    <div className='cardList__item__caption'>
                                        <div>{item.caption2}</div>
                                        <div>{item.caption}</div>
                                    </div>
                                    
                                    <div className='cardList__item__date'>{item.date}</div>
                                </div>
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default CardList;
